import styled from 'styled-components';
import { colors } from '../styles';

export const Container = styled.div`
  position: fixed;
  width: calc( 100% - 10%);
  height: 100%;
  overflow: hidden;
  padding:5%;
  display: flex;
  flex-direction: column;
  font-family: sans-serif;
  text-align: center;
  background: ${colors.dark};
`;

export const Bottom = styled.div`
  flex-grow: 1;
  padding-top: 20px;
`

export const Top = styled.div`
  flex-shrink: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 80%;
`
export const Image = styled.img`
  object-fit: contain;
  height: 100%;
  max-width: 100%;
`


export const Headline = styled.h1`
  text-align: center;
`;
