import React, {useEffect, useState} from "react";
import {Button} from "../styles";

const DownLoadButton = ({image}) => {
    const [currentBlob, setCurrentBlob] = useState(null)
    const [currentDownloadUrl, setCurrentDownloadUrl] = useState('');
    const currentFilename = 'virtual-selfiebox_' + Date.now() + '.jpg'

    const loadImage = async (imgData) => {
        console.log(imgData)
        const response = await fetch(imgData);
        return response.blob();
    }

    useEffect(() => {
        loadImage(image).then(r => {
            setCurrentBlob(r)
            setCurrentDownloadUrl(URL.createObjectURL(r))
        })
    }, [])

    const clickAnchor = properties => {
        const anchor = document.createElement('a');
        Object.assign(anchor, properties);
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
    };
    const downloadFile = () => {
        clickAnchor({
            href: currentDownloadUrl,
            download: currentFilename,
        });

    };

    const shareImage = () => {
        const fileToInclude = new File(
            [currentBlob],
            currentFilename,
            { type: 'image/jpeg', lastModified: Date.now()}
        )
        const filesArray = [fileToInclude];
        if(navigator.canShare && navigator.canShare({ files: filesArray })) {
            const shareObject = { files: filesArray };

            navigator.share(shareObject);
        } else {
            downloadFile();
        }
    }
    return (
        <>
            { image && <Button onClick={() => shareImage()}>Foto speichern</Button>}
        </>
    )
}

export default DownLoadButton
