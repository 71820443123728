import React from 'react';
import ReactDOM from 'react-dom';

import Landing from "./Landing";

const App = () => (
    <Landing />
);

const root = document.createElement('div');
root.setAttribute("id", "root")
document.body.appendChild(root);

ReactDOM.render(<App />, root);

export default App;
