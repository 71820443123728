import React from "react";
import {GlobalStyle} from "../styles";
import DownLoadButton, {Button} from "../DownLoadButton";
import {Bottom, Container, Image, Top} from "./styles";

const Landing = () => {
    const urlParams = new URLSearchParams(window.location.search)
    const imageSource = urlParams.get('image')

    return (
    <Container>
        <GlobalStyle />
        <Top>
            <Image src={imageSource} alt="" />
        </Top>
        <Bottom>
            <DownLoadButton image={imageSource} />
        </Bottom>
    </Container>
    )
}

export default Landing
