import styled, { createGlobalStyle } from 'styled-components';
import styledNormalize from 'styled-normalize';

import HelveticaNeueBoldWoff from '../assets/fonts/HelveticaNeue-Bold.woff'
import HelveticaNeueBoldTtf from '../assets/fonts/HelveticaNeue-Bold.ttf'

export const fontStyles = `
  @font-face {
    font-family: 'HelveticaNeueBold';
    src: url(${HelveticaNeueBoldTtf}) format('ttf'), url(${HelveticaNeueBoldWoff}) format('woff');
  }
`

export const colors = {
  black: '#000000',
  dark: '#1e1e1e',
  red: '#e0301e',
  white: '#ffffff',
};

export const GlobalStyle = createGlobalStyle`
  ${styledNormalize};
  ${fontStyles}
  body, html, #root {
    height: 100%;
  }
`;

export const Button = styled.button`
  border: 0;
  font-family: 'HelveticaNeueBold', sans-serif;
  text-transform: uppercase;
  color: ${colors.white};
  font-size: 1em;
  padding: 1.25em 4.75em;
  background-color: ${colors.red};
  outline: none;
  cursor: pointer;
  flex-grow: 1;
`;
